@use '@density/dust/dist/scss/utils' as dust;

.listItem {
  color: dust.$gray-900;
  background-color: transparent;
  padding-left: dust.$space-3;
  padding-right: dust.$space-3;
  margin-bottom: dust.$space-1;
  @include dust.text-preset(4);
  @include dust.text-unselectable;
  text-decoration: none;

  display: flex;
  align-items: center;
  height: dust.$space-6;
  border: 0px;
  border-radius: dust.$radius-200;
  width: 100%;
  cursor: pointer;

  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  transition: all 150ms ease-in-out;
}
.listItem:focus {
  outline: none;
  box-shadow: 0px 0px 0px 2px dust.$blue-200;
}
.listItem.active,
.listItem:hover {
  background-color: dust.$gray-100;
}
.listItem.small {
  @include dust.text-preset(4);
  height: dust.$space-5;
}

.listItem.disabled,
.listItem.disabled:hover {
  background-color: dust.$gray-200;
  cursor: not-allowed;
}

.listItem.dark {
  color: dust.$white;
}
.listItem.dark:focus {
  box-shadow: 0px 0px 0px 2px dust.$gray-500;
}
.listItem.dark.active,
.listItem.dark:hover {
  background-color: dust.$gray-600;
}

.listItem.danger {
  color: dust.$red-300;
}

.listItem.disabled,
.listItem.disabled:hover {
  background-color: dust.$gray-700;
  color: dust.$gray-300;
}

.listItemIcon {
  width: 16px;
  height: 16px;
  margin-right: dust.$space-3;
}
