@use '@density/dust/dist/scss/utils' as dust;

.loadingErrorWrapper {
  height: 120px;
  width: 320px;
}

.scrollingWrapper {
  padding: dust.$space-2;
  max-height: 320px;
  overflow-y: auto;
}

.nameAndAction {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: dust.$space-2;
}

.name {
  min-width: 128px;
  text-align: left;
  margin-right: dust.$space-5;
  @include dust.text-preset(5);
}
