@use '@density/dust/dist/scss/utils' as dust;

.backdrop {
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 1;
}

.wrapper {
  position: relative;
  outline: none;
  color: dust.$gray-700;
  z-index: 2;
}

.target {
  white-space: nowrap;
}

.popup {
  position: absolute;
  /* top, left, bottom, right set as inline style */

  opacity: 0;
  pointer-events: none;
  transition: all 150ms ease-in-out;

  border: 1px solid dust.$gray-300;
  border-radius: dust.$radius-200;
  box-shadow: 0 8px 10px 0 rgba(0, 0, 0, 0.08);
  background-color: #fff;
  z-index: 3;
  overflow: hidden;

  @include dust.text-preset(4);
}
.popup.open {
  opacity: 1;
  pointer-events: auto;
}
.popup.anchorTop {
  top: none;
  bottom: -9px;
}
.popup.dark {
  border: 1px solid dust.$gray-500;
  background-color: dust.$gray-700;
  color: dust.$gray-200;
}

.popup.padding {
  padding: 8px;
}
