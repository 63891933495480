.middleEllipsis {
  display: inline-flex;
  width: 100%;
  white-space: nowrap;
}
.middleEllipsis .left {
  flex-grow: 0;
  flex-shrink: 1;
  text-overflow: ellipsis;
  overflow: hidden;
}
.middleEllipsis .right {
  flex-grow: 0;
  flex-shrink: 0;
  white-space: nowrap;
}

.inFlexParent {
  width: 0px;
  flex-grow: 1;
}
