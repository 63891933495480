@use '@density/dust/dist/scss/utils' as dust;

.extentsSlider {
  position: relative;
  width: 100%;
  height: dust.$space-8;
  margin-top: dust.$space-4;
}

.extentsSliderBar {
  width: 100%;
  height: dust.$space-6;
  background-color: dust.$gray-100;
  box-shadow: 0px 2px 4px rgba(34, 42, 46, 0.1);
  border-radius: dust.$radius-100;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 2px 4px rgba(34, 42, 46, 0.1);
}

.extentsSliderBarInner {
  display: flex;
  background-color: dust.$blue-400;
  border-radius: dust.$radius-100;

  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;

  cursor: grab;
  overflow: hidden;
}

.extentsSliderMarker {
  position: absolute;
  cursor: pointer;
}

.extentsSliderMarkerLabel {
  position: absolute;
  top: -52px;
  @include dust.text-preset(3);
  @include dust.text-unselectable;
  color: dust.$gray-500;
}
