@use '@density/dust/dist/scss/utils' as dust;

.heightMapExtentsSliderInner {
  background: linear-gradient(
    270deg,
    #ffc596 0%,
    #ee853a 25.52%,
    #bc3865 51.04%,
    #1b4693 76.56%,
    #1c2a59 100%
  );
  width: 100%;
  height: 100%;
}
