@use '@density/dust/dist/scss/utils' as dust;

.topBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
  flex-shrink: 0;

  height: dust.$space-7;
  background-color: dust.$gray-900;
  color: dust.$gray-100;
  padding-left: dust.$space-3;
  padding-right: dust.$space-3;
}

.topBarBrand {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: dust.$space-2;

  @include dust.text-preset(6);
  font-weight: dust.$font-weight-bold;
  @include dust.text-unselectable;
}

.topBarBrandIcon {
  color: dust.$blue-200;
  background-color: dust.$gray-600;
  border-radius: dust.$radius-200;
  width: dust.$space-5;
  height: dust.$space-5;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: dust.$space-3;
}
