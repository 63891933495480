@use '@density/dust/dist/scss/utils' as dust;

.wrapper {
  height: 32px;
  border-radius: dust.$radius-200;
  background-color: dust.$gray-800;

  display: flex;
}
.wrapper.disableAutoSave {
  background-color: dust.$red-500;
}

.status {
  height: 32px;
  width: 136px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: dust.$space-3;
  background-color: dust.$gray-700;
  border-radius: dust.$radius-200;
  margin-left: 4px;
  padding-left: dust.$space-4;
  color: dust.$white;
  @include dust.text-unselectable;
  @include dust.text-preset(4);
  font-weight: bold;
}
.wrapper.disableAutoSave .status {
  background-color: dust.$red-400;
}

.dot {
  width: dust.$space-3;
  height: dust.$space-3;
  border-radius: 50%;
  background-color: dust.$white;
}
.dot.success {
  background-color: dust.$green-400;
}
.dot.warning {
  background-color: dust.$yellow-400;
}
.dot.danger {
  background-color: dust.$red-400;
}
