@use '@density/dust/dist/scss/utils' as dust;

.wrapper {
  position: fixed;
  bottom: dust.$space-4;
  right: dust.$space-4;
  background-color: dust.$gray-900;
  border: 2px solid dust.$blue-200;
  border-radius: dust.$radius-200;
  width: 320px;
  color: dust.$white;
  padding: dust.$space-3;
  z-index: 999;
}

.body {
  @include dust.text-preset(5);
  padding-left: dust.$space-3;
  padding-right: dust.$space-3;
}

.body a {
  color: dust.$white;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: dust.$space-8;
}
