@use '@density/dust/dist/scss/utils' as dust;

.keyboardShortcut {
  height: 21px;
  min-width: 21px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid dust.$gray-500;
  border-radius: 4px;
  font-size: 12px;
  background-color: dust.$gray-200;
  padding: 1px 4px;
  color: dust.$gray-600;
  margin-right: dust.$space-2;
}
.keyboardShortcut.dark {
  background-color: dust.$gray-900;
  color: dust.$gray-200;
  border-color: dust.$gray-400;
}
