@use '@density/dust/dist/scss/utils' as dust;

.formLabel {
  display: flex;
  flex-direction: column;
  color: dust.$gray-700;
  margin-bottom: dust.$space-3;
}

// The actual label element within the `form-label`
.formLabelLabel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  @include dust.text-preset(3);
  @include dust.text-unselectable;
  font-weight: dust.$font-weight-bold;
  margin-bottom: dust.$space-2;
  color: dust.$gray-600;
  position: relative;
}
// The first .form-label-label should not have a top margin.
.formLabel:first-child .formLabelLabel {
  margin-top: 0px;
}

// The last .form-label should have some bottom margin.
.formLabel:last-child,
.formLabel:last-of-type {
  margin-bottom: dust.$space-3;
}

.formLabel > span {
  flex: 1;
  height: 34px;
}

.formLabelRequired {
  color: dust.$blue-400;
  @include dust.text-preset(2);
  @include dust.text-unselectable;
}
