@use '@density/dust/dist/scss/utils' as dust;

.editableName {
  display: flex;
  align-items: center;
  @include dust.text-preset(6);
  @include dust.text-unselectable;
  margin-left: dust.$space-2;
}
.editableName.showEllipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.editableName.clickable {
  cursor: pointer;
}

.editableNameEditHint {
  display: flex;
  align-items: center;

  @include dust.text-preset(4);
  @include dust.text-unselectable;
  pointer-events: none;

  color: dust.$gray-400;
  margin-left: dust.$space-3;
}
.editableNameEditHintIcon {
  margin-right: dust.$space-1;
  height: 16px;
}
