@use '@density/dust/dist/scss/utils' as dust;

.addFilterWell {
  padding: dust.$space-4;
  border-radius: dust.$radius-200;
  color: dust.$gray-900;
  background-color: dust.$gray-100;
  border: 1px solid dust.$gray-300;

  display: flex;
  flex-direction: column;
  gap: dust.$space-4;
  align-items: center;
  justify-content: center;
}

.addFilterWellText {
  text-align: center;
}

.filterBox {
  border: 1px solid dust.$gray-300;
  margin-bottom: dust.$space-3;
  padding: dust.$space-3;
  position: relative;
  border-radius: dust.$radius-200;
}

.filterBoxDelete {
  position: absolute;
  top: dust.$space-3;
  right: dust.$space-3;
  z-index: 100;
}

.filterLinesHeaderWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: dust.$space-6;
  width: 100%;
}

.boundingRegionWrapper {
  display: flex;
  flex-direction: column;
  gap: dust.$space-2;
}

.lineColorsWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: dust.$space-2;
  padding-top: dust.$space-2;
}

.addFilterRightAlignBar {
  display: flex;
  height: dust.$space-6;
  align-items: center;
  justify-content: flex-end;
}

.seperator {
  height: 100%;
  width: 0px;
  border: 1px solid dust.$gray-500;
  margin-left: dust.$space-2;
  margin-right: dust.$space-2;
}
