@use '@density/dust/dist/scss/utils' as dust;

.wrapper {
  position: absolute;
  bottom: dust.$space-3;
  right: dust.$space-3;

  display: flex;
  align-items: flex-end;
  gap: dust.$space-1;
}
