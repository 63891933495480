@use '@density/dust/dist/scss/utils' as dust;

.appBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-grow: 0;
  flex-shrink: 0;

  height: dust.$space-8;
  padding-left: dust.$space-3;
  padding-right: dust.$space-3;
  border-bottom: 1px solid dust.$gray-300;
}

.title {
  @include dust.text-preset(6);
  @include dust.text-unselectable;
}

.appBar.dark {
  background-color: dust.$gray-800;
  color: dust.$gray-200;
  border-bottom: 1px solid dust.$gray-900;
}
