@use '@density/dust/dist/scss/utils' as dust;

.addFloorplanModalWrapper {
  padding: dust.$space-3;
}

.addFloorplanImage {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: dust.$space-5;
  border: 1px dashed dust.$gray-200;
  border-radius: dust.$radius-200;
  background-color: dust.$white;
  cursor: pointer;
  height: 200px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  color: dust.$gray-500;
  @include dust.text-preset(5);
}
.addFloorplanImage:hover {
  background-color: dust.$gray-000;
  border: 1px solid dust.$gray-200;
}
.addFloorplanImage:focus {
  border: 1px solid dust.$blue-400;
  box-shadow: 0px 0px 0px 2px dust.$blue-200;
}
.addFloorplanImage.readOnly {
  cursor: default;
}
.addFloorplanImage:disabled {
  border: 0px solid dust.$gray-200;
  background-color: dust.$white;
}
.addFloorplanImageFileTypes {
  margin-top: dust.$space-2;
  @include dust.text-preset(3);
}

.heightMapSelected {
  color: dust.$gray-500;
  @include dust.text-preset(3);
  @include dust.text-unselectable;
}

.progressBarWrapper {
  width: 100%;
  height: 4px;
  background-color: dust.$gray-300;
  border-radius: 2px;
  overflow: hidden;
  margin-top: 32px;
  margin-bottom: 8px;
}
.progressBarInner {
  height: 100%;
  background-color: dust.$blue-400;
}
.progressBarPercent {
  display: flex;
  justify-content: flex-end;
  font-weight: 500;
  color: dust.$gray-400;
  margin-top: 4px;
  margin-bottom: 8px;
}
