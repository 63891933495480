@use '@density/dust/dist/scss/utils' as dust;

.validations {
  margin-top: dust.$space-3;
  padding: dust.$space-3;
  margin-top: 8px;
  margin-bottom: 8px;
  background-color: dust.$gray-100;
  border-radius: dust.$radius-200;
  @include dust.text-preset(3);
}
.validationsBadge {
  display: flex;
  align-items: center;
  justify-content: center;

  color: dust.$white;
  background-color: dust.$gray-400;
  @include dust.text-preset(3);
  @include dust.text-unselectable;
  font-weight: dust.$font-weight-bold;
  height: 18px;
  min-width: 18px;
  border-radius: dust.$radius-300;
  padding-left: dust.$space-2;
  padding-right: dust.$space-2;
  margin-right: dust.$space-2;
}
.validationsWarningsHeader,
.validationsErrorsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: dust.$space-2;
  &:last-child {
    margin-bottom: 0px;
  }
  &.clickable {
    cursor: pointer;
  }
}
.validationsWarningsName,
.validationsErrorsName {
  display: flex;
  align-items: center;
  gap: dust.$space-2;
  @include dust.text-preset(4);
  @include dust.text-unselectable;
  font-weight: dust.$font-weight-bold;
}
.validationsWarningsNameDot,
.validationsErrorsNameDot {
  width: dust.$space-3;
  height: dust.$space-3;
  border-radius: 50%;
  background-color: dust.$gray-400;
  margin-right: dust.$space-1;
}
.validationsWarningsNameDot {
  background-color: dust.$yellow-400;
}
.validationsErrorsNameDot {
  background-color: dust.$red-400;
}

.validationSectionItem {
  @include dust.text-unselectable;
  margin-bottom: dust.$space-1;
}
.validationSectionItemHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.validationSectionItemHeaderName {
  display: flex;
  align-items: center;
  gap: dust.$space-2;
}
.validationSectionItemBody {
  color: dust.$gray-400;
}
